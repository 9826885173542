import React from 'react';
import { Root } from './style';

import { Link } from 'gatsby';

import PageHeader from 'components/Common/PageHeader';
import RawHTML from 'components/Common/RawHTML';
import SiteImage from 'components/Common/SiteImage';

import { toResourceURL } from 'utils';
import vars from 'variables';

const CaseStudies = ({pageContext: { caseStudies }}) => {
  const resourceURL = toResourceURL(vars.pages.caseStudies);

  return (
    <Root className="body-width">
      <PageHeader page="caseStudies"/>
      <ul>
        {caseStudies.map(caseStudy =>
          <li key={caseStudy.id}>
            <div className="image">
              <Link to={resourceURL(caseStudy)}>
                <SiteImage image={caseStudy.image}/>
              </Link>
            </div>
            <div className="details">
              <label>Case study</label>
              <h2>
                <Link to={resourceURL(caseStudy)}>
                  <RawHTML html={caseStudy.title}/>
                </Link>
              </h2>
              <p>{caseStudy.intro}</p>
              <Link className="button blue desktop" to={resourceURL(caseStudy)}>
                Learn more
              </Link>
              <Link className="mobile more" to={resourceURL(caseStudy)}>
                Learn more
              </Link>
            </div>
          </li>
        )}
      </ul>
    </Root>
  );
};

export default CaseStudies;
