import styled from 'styled-components';

import vars from 'variables';

import background from 'assets/CaseStudies/background.png';
import arrow from 'assets/CaseStudies/right-blue.svg';

const Root = styled.div`
  li {
    padding: 20px 0;
  }

  li div.image {
    background: url(${background}) no-repeat;
    border-radius: 15px;
  }

  li div.details label {
    padding: 4px 8px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.7rem;
    letter-spacing: 1px;
    background: rgb(48, 50, 64);
    border-radius: 3px;
  }

  li div.details h2 span {
    color: white;
  }

  li div.details a.button {
    padding-left: 50px;
    padding-right: 50px;
    letter-spacing: 0.5px;
  }

  li div.image img {
    object-fit: cover;
    border-radius: 300px;
  }

  ${vars.desktop.mediaQuery} {
    ul {
      padding: 100px 0 150px;
    }

    li {
      display: flex;
    }

    li div.image {
      flex: 0 0 730px;
    }

    li div.image img {
      width: 435px;
      height: 435px;
      margin-top: 40px;
      margin-left: 65px;
    }

    li div.details {
      padding: 60px;
    }

    li div.details h2 {
      margin: 15px 0 20px;
      font-size: 1.7rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 155%;
    }

    li div.details p {
      margin-bottom: 45px;
      color: #AAA;
      line-height: 160%;
    }
  }

  ${vars.mobile.mediaQuery} {
    ul {
      padding: 45px 0 30px;
    }

    li div.image {
      background-size: auto 223px;
    }

    li div.image img {
      width: 200px;
      height: 200px;
      margin-top: 13px;
      margin-left: 25px;
    }

    li div.details label {
      display: none;
    }

    li div.details h2 {
      margin: 18px 0 15px 0;
      font-size: 0.85rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 160%;
    }

    li div.details p {
      margin-bottom: 25px;
    }

    a.more {
      padding-right: 20px;
      font-weight: bold;
      text-transform: uppercase;
      background: url(${arrow}) no-repeat center right;
    }
  }
`;

export { Root };
